$primary-light-color: rgb(0 44 138);
$primary-light-color-contrast: white;

$accent-light-color: rgb(27, 155, 216);
$accent-light-color-contrast: white;

// default color vars
$color-first: rgb(107 114 128);

$body-color: #F1F2F7;
$hover-color: $accent-light-color;
$warning-button-background: #F5743E;
$warning-button-color: white;

//loading background
$loading-background-color: rgba(241, 242, 247, 0.8);

//spinner
$spinner-color: #333;
$svg-spinner-color: $spinner-color;
$svg-spinner-button-color: white;
$svg-table-spinner-color: rgba(0, 0, 0, 0.87);

//disabled-cover
$disabled-cover-background-color: rgba(250, 250, 250, .7);

//tooltip
$tooltip-icon-color: $color-first;
$tooltip-color: white;
$tooltip-background-color: gray;
$tooltip-arrow-color: gray;

//form field
$form-field-icon-color: gray;

// - scrollbar vars
$scrollbar-track-background-color: #DCDBD9;
$scrollbar-thumb-background-color: #B5B5B5;

//select
$select-option-color: white;
$select-input-border-color: #CCC;

// multiselect
$multi-select-label-color: rgb(244 244 245);
$multi-select-label-border-color: rgb(212 212 216);


//pulse animation
$pulse-0-color: #F48886;
$pulse-50-color: gray;


//material components
$card-title-color: $color-first;
$table-buttons-column-color: $color-first;
