// This file was generated by running 'ng generate @angular/material:m3-theme'.
// Proceed with caution if making changes to this file.

@use 'sass:map';
@use '@angular/material' as mat;

@include mat.all-component-typographies();
@include mat.core();

// Note: Color palettes are generated from primary: #002c8a, tertiary: #1b9bd8
$_palettes: (
  primary: (
    0: #000000,
    10: #00164f,
    20: #00277e,
    25: #0c3390,
    30: #1f3f9b,
    35: #2e4ca8,
    40: #3b58b5,
    50: #5671d0,
    60: #708cec,
    70: #8fa7ff,
    80: #b6c4ff,
    90: #dce1ff,
    95: #eff0ff,
    98: #faf8ff,
    99: #fefbff,
    100: #ffffff,
  ),
  secondary: (
    0: #000000,
    10: #161b2c,
    20: #2b3042,
    25: #363b4d,
    30: #424659,
    35: #4d5265,
    40: #595d72,
    50: #72768b,
    60: #8c90a6,
    70: #a6aac1,
    80: #c2c5dd,
    90: #dee1f9,
    95: #eff0ff,
    98: #faf8ff,
    99: #fefbff,
    100: #ffffff,
  ),
  tertiary: (
    0: #000000,
    10: #001e2e,
    20: #00344d,
    25: #00405d,
    30: #004c6d,
    35: #00587e,
    40: #006590,
    50: #007fb4,
    60: #189ad7,
    70: #46b5f3,
    80: #87ceff,
    90: #c8e6ff,
    95: #e5f2ff,
    98: #f6faff,
    99: #fbfcff,
    100: #ffffff,
  ),
  neutral: (
    0: #000000,
    10: #1b1b1f,
    20: #303034,
    25: #3b3b3f,
    30: #47464a,
    35: #525256,
    40: #5e5e62,
    50: #77767a,
    60: #919094,
    70: #acaaaf,
    80: #c8c6ca,
    90: #e4e1e6,
    95: #f2f0f4,
    98: #fbf8fd,
    99: #fefbff,
    100: #ffffff,
    4: #0e0e11,
    6: #131316,
    12: #1f1f23,
    17: #292a2d,
    22: #343438,
    24: #39393c,
    87: #dbd9de,
    92: #eae7ec,
    94: #efedf1,
    96: #f5f3f7,
  ),
  neutral-variant: (
    0: #000000,
    10: #1a1b23,
    20: #2f3038,
    25: #3a3b43,
    30: #45464f,
    35: #51525b,
    40: #5d5e67,
    50: #767680,
    60: #90909a,
    70: #aaaab4,
    80: #c6c6d0,
    90: #e2e1ec,
    95: #f0f0fa,
    98: #faf8ff,
    99: #fefbff,
    100: #ffffff,
  ),
  error: (
    0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes,  neutral-variant),
  error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

$light-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: $_primary,
    tertiary: $_tertiary,
  )
));

html {
  // Include the theme mixins for other components you use here.
  @include mat.all-component-themes($light-theme);
  @include mat.color-variants-backwards-compatibility($light-theme);
}
