@use "sass:math";
@import './colors.scss';
@tailwind base;
@tailwind components;
@tailwind utilities;

.shm-container {
  padding-left: 10px;
  padding-top: 10px;
  overflow: auto;
  position: relative;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

body {
  margin: 0;
  background-color: $body-color;
  overflow: hidden;
}

/* --- card Styles --- */
.mat-mdc-card {
  margin: 0 10px 10px 0px;
  padding: 4px 16px 16px 16px;

  .mat-mdc-card-title {
    padding: 4px;
    font-size: 16px;
    text-transform: uppercase;
    color: $card-title-color;
    display: flex !important;
    justify-content: flex-start;
    align-items: center;

    button {
      margin-left: auto;

      &:hover {
        color: $hover-color;
      }
    }

    p {
      line-height: 16px;
    }

    .mat-icon.tooltip-icon {
      font-size: 16px;
      line-height: 16px;
      width: 16px;
      height: 16px;
      overflow: inherit;
    }
  }

  .mat-mdc-card-content {
    font-size: 14px;
    .mat-icon.tooltip-icon {
      font-size: 16px;
      line-height: 16px;
      width: 16px;
      height: 16px;
    }
  }
}

/* --- Spinner Styles --- */
.spinner {
  top: calc(50% - 10px);
  right: calc(50% - 40px);
  position: absolute;
  width: 80px;
  text-align: center;

  & > div {
    background-color: $spinner-color;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 9999px;
  }

  .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
}

.absolute-spinner {
  top: calc(50% - 16px);
  right: calc(50% - 16px);

  .mat-mdc-progress-spinner {
    svg circle {
      stroke: $svg-spinner-color;
    }
  }
}

.p-box {
  padding: 0% 5%;
}

.loading-background {
  background-color: $loading-background-color;
  z-index: 9999;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0)
  }
  40% {
    -webkit-transform: scale(1.0)
  }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

/* --- general styles --- */
/* --- offer --- */
.center-one-line-vert {
  height: 50px;
}

.spinner_container_pic_landscape_mov {
  width: 256px;
}

.spinner_container_pic_portrait {
  width: 190px;
}

.spinner_container_pdf {
  width: 172px;
}

// offer preview
.offer-preview {
  .op-text {
    width: calc(60% - 24px);
    padding: 2px 0px 0px 4%;
  }

  .op-more-icon {
    top: 35%;
  }
}

/* --- Tooltip style--- */
.tooltip-icon {
  color: $tooltip-icon-color;
  vertical-align: text-bottom;
}

.tooltip-text-icon {
  font-size: 20px;
}

.tippy-box {
  .tippy-content {
    text-align: center;
  }
}

.tippy-box[data-theme~='shm'] {
  background-color: $tooltip-background-color;
  color: $tooltip-color;

  .tippy-arrow {
    color: $tooltip-arrow-color;
  }
}

.pulse {
  -webkit-animation: pulse 1s linear infinite;
  -moz-animation: pulse 1s linear infinite;
  -ms-animation: pulse 1s linear infinite;
  animation: pulse 1s linear infinite;
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    color: $pulse-0-color;
  }
  50% {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -o-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
    color: $pulse-50-color;
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

/* --- scrollbar --- */
// - scrollbar vars
$scrollbar-width: 8px;

*::-webkit-scrollbar-track {
  background-color: $scrollbar-track-background-color;
}

*::-webkit-scrollbar {
  width: $scrollbar-width;
  height: $scrollbar-width;
}

*::-webkit-scrollbar-thumb {
  border-radius:  $scrollbar-width;
  background-color: $scrollbar-thumb-background-color;
}

/* --- datatable -- */
@-moz-keyframes spin {
  100% { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform:rotate(360deg);
  }
}

// table styles
.table-container {
  table {
    .mat-mdc-progress-spinner {
      svg circle {
        stroke: $svg-table-spinner-color;
      }
    }

    .mat-column-buttons, .mat-column-access_token {
      button:not([disabled]) {
        color: $table-buttons-column-color;

        &:hover {
          color: $hover-color;
        }
      }
    }

    th.mat-mdc-header-cell, td.mat-mdc-cell, td.mat-mdc-footer-cell {
      padding-right: 10px;
    }
  }
}

// dialog styles
.mat-mdc-dialog-content {
  .mat-icon.tooltip-icon {
    font-size: 20px;
    line-height: 20px;
    width: 20px;
    height: 20px;
  }

  .ng-select.ng-select-multiple {
    line-height: 1.15;
  }
}

.mat-mdc-dialog-actions {
  &.button-container {
    button {
      margin: 4px !important;

      &.warning {
        background-color: $warning-button-background;
        color: $warning-button-color;
      }
    }
  }
}

//toolbar styles
mat-toolbar {
  ng-select {
    .ng-dropdown-panel .ng-dropdown-panel-items {
      max-height: 300px !important;
    }
  }
}

//side nav styles
mat-sidenav {
  background-color: darken($primary-light-color, 10%) !important;

  *::-webkit-scrollbar-track {
    background-color: darken($primary-light-color, 10%) !important;
  }

  *::-webkit-scrollbar-thumb {
    background-color: $accent-light-color !important;
  }

  .mat-mdc-list-item {
    .mat-mdc-list-item-icon, .mat-mdc-list-item-title {
      color: $primary-light-color-contrast !important;
    }

    /* Change the link color on hover */
    &:hover {
      background-color: darken($primary-light-color, 15%) !important;
    }

    &.active {
      background-color: darken($primary-light-color, 15%) !important;
      .mat-mdc-list-item-icon, .mat-mdc-list-item-title {
        color: $accent-light-color !important;
      }
    }
  }
}

// form field styles
.mat-mdc-form-field {
  .mat-icon {
    color: $form-field-icon-color;
  }
}

// select styles
.ng-dropdown-panel {
  border-radius: 6px;

  &.ng-select-top {
    bottom: 38px;
  }

  .ng-dropdown-panel-items {
    border-radius: 6px;

    .ng-option {
      &.ng-option-selected {
        background-color: $accent-light-color !important;
        color: $select-option-color !important;
      }
    }
  }
}

.ng-select.ng-select-multiple  {
  &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    box-shadow: none;
  }

  &.ng-select-multiple .ng-select-container .ng-value-container {
    padding: 0px;

    .ng-input {
      padding: 0px;
    }

    .ng-placeholder {
      padding-left: 10px;
      top: 11px;
    }

    .ng-value {
      background-color: $multi-select-label-color;
      border: 1px solid $multi-select-label-border-color;
      border-radius: 4px;
      display: inline-block;
      padding: 4px 4px 4px 0px;
      position: relative;
      white-space: unset;

      .ng-value-icon {
        &:hover {
          color: $hover-color;
          background: none;
        }

        &.left {
          font-size: 18px;
          line-height: 1;
          border-right: 1px solid $multi-select-label-border-color;
        }
      }
    }
  }

  .ng-select-container {
    border: 0;
    display: block;

    .ng-value-container {
      margin-bottom: 38px;
      max-height: 180px;
      overflow-y: auto;

      .ng-input {
        border: 1px solid $select-input-border-color;
        border-radius: 4px;
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;

        & > input {
          cursor: text;
          height: 36px;
          width: calc(100% - 38px);
          margin: 0;
          padding-left: 8px;
          padding-right: 30px;
          vertical-align: middle;
        }
      }
    }

    .ng-arrow-wrapper {
      position: absolute;
      right: 0;
      bottom: 11px;
      z-index: 2;
    }

    .ng-value {

      .ng-value-label {
        float: left;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .ng-value-icon {
        float: left;
      }
    }
  }
}

ng-select {
  &.dialog-select {
    .ng-dropdown-panel {
      .ng-dropdown-panel-items {
        max-height: 150px;
      }
    }
  }

  &.value-hidden {
    &.ng-select-multiple .ng-select-container .ng-value-container {
      .ng-value {
        display: none !important;
      }
    }
  }
}

// code views
.no-code-template-available {
  margin: 12%;
  font-family: monospace,monospace;
  font-size: 16px;
}

.disabled-cover {
  background-color: $disabled-cover-background-color;
}

//location preview
location-preview {
  &::-webkit-scrollbar {
    display: none;
  }
}

//location image cropper
.location-img-cropper {
  img-cropper {
    max-width: 520px;
    max-height: 288px;

    canvas {
      width: 100%;
      max-width: 520px;
      max-height: 288px;
      aspect-ratio: 520 / 288;
    }
  }
}

//offer image cropper
.offer-img-cropper {
  img-cropper {
    max-width: 525px;
    max-height: 393px;

    canvas {
      width: 100%;
      max-width: 525px;
      max-height: 393px;
      aspect-ratio: 525 / 393;
    }
  }
}
